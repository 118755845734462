import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ArrowLeft, Copy, Lock, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TreffasSwicth from "src/components/TreffasSwitch";
import TreffasTextField from "src/components/TreffasTextField";
import { PlanSubscription } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";
import Constant from "src/utils/Constant";
import TreffasStyle from "src/utils/TreffasStyle";

const Embed = ({ bookingURL, close, onChangeCategory, hasHeaderOption }) => {
  const { t } = useTranslation();
  const [type, setType] = React.useState("script");

  const { showSnackbar } = useSnackbarContext();
  const [removes, setRemoves] = React.useState({
    header: false,
    footer: false,
  });

  const { planSubscription } = useContext(PlanSubscription);
  const navigate = useNavigate();

  const [withLogo, setWithLogo] = React.useState(true);

  let header = Constant.encrypto(removes.header ? "YES" : "NO");
  let footer = Constant.encrypto(removes.footer ? "YES" : "NO");

  const baseUrl = window.location.origin;

  let withHeaderURL = `${bookingURL}?display=embeded&header=${encodeURIComponent(
    header
  )}&footer=${encodeURIComponent(footer)}`;

  let withLogoURL = `${bookingURL}?display=embeded&footer=${encodeURIComponent(
    Constant.encrypto(withLogo ? "YES" : "NO")
  )}`;

  let embedURL = hasHeaderOption ? withHeaderURL : withLogoURL;

  let embedScript = `<script src="${baseUrl}/js/embed.js" data-url="${embedURL}"> </script>`;
  let embedIframe = `<iframe width="100%" height="100%" src="${embedURL}" title="Packages List"> </script>`;

  let hasEmbed = planSubscription?.inclusive?.package?.embed ?? false;

  return (
    <Box>
      <Box
        sx={{
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={() => onChangeCategory("booking")}>
              <ArrowLeft size={20} />
            </IconButton>
          </Box>

          <Box>
            <IconButton onClick={() => close()}>
              <X size={20} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mt: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Mukta",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "26px",
              lineHeight: "26px",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {t("package.label.booking.embed_on_your_website")}
          </Typography>
        </Box>

        <Box sx={{ mt: "8px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              textAlign: "center",
              color: "#6E757C",
            }}
          >
            {t("package.label.booking.embed_on_your_website.description")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: "1px solid #EAECEF",
          display: "flex",
          justifyContent: "center",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            py: "17.5px",
            ...(type === "script" && {
              borderBottom: "1px solid #D97054",
            }),
            cursor: "pointer",
          }}
          onClick={() => setType("script")}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "normal",
              color: type === "script" ? "#1D1B1B" : "#697077",
            }}
          >
            Script
          </Typography>
        </Box>

        <Box
          sx={{
            py: "17.5px",
            ...(type === "iframe" && {
              borderBottom: "1px solid #D97054",
            }),
            cursor: "pointer",
          }}
          onClick={() => setType("iframe")}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "normal",
              color: type === "iframe" ? "#1D1B1B" : "#697077",
            }}
          >
            Iframe
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "32px",
        }}
      >
        <Box>
          <TreffasTextField
            InputProps={{ readOnly: true }}
            value={type === "script" ? embedScript : embedIframe}
            fullWidth
            sx={{
              ...TreffasStyle.formInputLarge("textarea"),
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px !important",
                backgroundColor: "#F4F5F6",
                minHeight: "100%",
              },
              "& .MuiInputBase-input": {
                fontWeight: 400,
                fontSize: "13px",
                color: "#353A3F",
              },
            }}
            multiline
            minRows={3}
          />
        </Box>
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "16px",
        }}
      >
        {hasHeaderOption ? (
          // show with header and footer option
          <Box>
            <Box
              sx={{
                mb: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Mukta",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "11px",
                  lineHeight: "14px",
                  letterSpacing: "0.07em",
                  color: "#6E757C",
                  textTransform: "uppercase",
                }}
              >
                {t("package.label.booking.embed_options")}
              </Typography>
            </Box>

            <Box
              sx={{
                backgroundColor: "#F4F5F6",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  height: "42px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: "8px 16px",
                  borderBottom: "1px solid #EAECEF",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#000000",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("package.label.booking.header")}
                  </Typography>
                </Box>
                <Box>
                  <TreffasSwicth
                    checked={removes.header}
                    onChange={(e) =>
                      setRemoves({ ...removes, header: e.target.checked })
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  height: "42px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: "8px 16px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#000000",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("package.label.booking.footer")}
                  </Typography>
                </Box>
                <Box>
                  <TreffasSwicth
                    checked={removes.footer}
                    onChange={(e) =>
                      setRemoves({ ...removes, footer: e.target.checked })
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          // show with treffas logo option
          <Box
            sx={{
              height: "42px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "8px 16px",
              backgroundColor: "#F4F5F6",
              borderRadius: "8px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "18px",
                  textTransform: "capitalize",
                }}
              >
                {t("package.label.booking.remove_treffas_logo")}
              </Typography>
            </Box>
            <Box>
              <TreffasSwicth
                checked={withLogo}
                onChange={(e) => setWithLogo(e.target.checked)}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "32px",
          pb: "40px",
        }}
      >
        <CopyToClipboard
          text={type === "script" ? embedScript : embedIframe}
          onCopy={() =>
            showSnackbar(
              t("individual.booking.embed_code.copy.clipboard"),
              "success"
            )
          }
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              minHeight: "44px",
              fontWeight: 700,
              fontSize: "15px",
              lineHeight: "20px",
              borderRadius: "8px",
              ...TreffasStyle.buttonHover,
            }}
            startIcon={hasEmbed ? <Copy size={18} /> : <Lock size={18} />}
            disabled={!hasEmbed}
          >
            {t("package.button.copy_embed_code")}
          </Button>
        </CopyToClipboard>

        {!hasEmbed && (
          <Box sx={{ mt: "16px" }}>
            <Typography
              sx={{
                color: "#6E757C",
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              Access this feature with{" "}
              <Typography
                variant={"caption"}
                sx={{
                  color: "#0173FE",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/app/billings/plans/upgrade`)}
              >
                Treffas Pro
              </Typography>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Embed;
